import CloudIcon from "../images/icons/cloud.svg";
import DevelopmentIcon from "../images/icons/development.svg";
import MobileIcon from "../images/icons/mobile-apps.svg";
import EcommerceIcon from "../images/icons/ecommerce.svg";
import IntegrationsIcon from "../images/icons/integration.svg";
import MachineLearning from "../images/icons/machine-learning.svg";

export function Services() {
  return (
    <div id="services" className="section">
      <div className="container text-center">
        <div className="margin-bottom-70">
          <div className="row text-center">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="font-weight-normal">What We Do</h2>
              <p>
                We specialize in delivering top-notch solutions tailored to your
                needs. Whether you require custom software development, mobile
                app creation, website design and development, or SEO services,
                our experienced team is dedicated to ensuring your project's
                success. Our local team of expert software developers, web
                developers, SEO specialists, and UX designers provides
                continuous guidance, development, and support throughout the
                entire process.
              </p>
            </div>
          </div>
        </div>
        <div className="row icon-5xl">
          <div className="col-12 col-md-4 service-container">
            <img src={CloudIcon} className="service-icon" alt="cloud" />
            <h5 className="font-weight-normal margin-top-20">Cloud</h5>
            <div className="left-align">
              <p>
                Our in house experts have extensive experience building and
                deploying business applications across AWS and Azure.
              </p>
              <ul className="list-icon list-icon-check feature-check">
                <li>
                  Cloud migration for existing onsite applications and services
                </li>
                <li>High Availability 99.995% Uptime Guarantee</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 service-container">
            <img
              src={DevelopmentIcon}
              className="service-icon"
              alt="development"
            />
            <h5 className="font-weight-normal margin-top-20">Applications</h5>
            <div className="left-align">
              <p>
                Our team have decades of experience working with customers to
                understand their business needs and building custom business
                applications
              </p>
              <ul className="list-icon list-icon-check feature-check">
                <li>Web applications</li>
                <li>Desktop applications</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 service-container">
            <img src={MobileIcon} className="service-icon" alt="mobile" />
            <h5 className="font-weight-normal margin-top-20">Mobile</h5>
            <div className="left-align">
              <p>
                We can build mobile applications that your customers will love
                using, Our expert team of mobile designers and developers can
                design mobile apps with a world-class user experience.
              </p>
              <ul className="list-icon list-icon-check feature-check">
                <li>Native iOS and Android Development</li>
                <li>Cross-platform development</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 service-container">
            <img src={EcommerceIcon} className="service-icon" alt="ecommerce" />
            <h5 className="font-weight-normal margin-top-20">eCommerce</h5>
            <div className="left-align">
              <p>
                We specialize in setting up online businesses with Shopify. From
                simple online stores to comprehensive integrations with
                multi-warehouses, we have you covered.
              </p>
              <ul className="list-icon list-icon-check feature-check">
                <li>Mobile-friendly website designs</li>
                <li>Inventory Management</li>
                <li>Shipping integration and configuration</li>
                <li>Warehouse integration</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 service-container">
            <img
              src={IntegrationsIcon}
              className="service-icon"
              alt="integration"
            />
            <h5 className="font-weight-normal margin-top-20">Integration</h5>
            <div className="left-align">
              <p>
                Larger projects inevitably require integrating with external
                business systems. Our developers can provide technical expertise
                and integration development work to provide a solution for your
                business needs.
              </p>
              <ul className="list-icon list-icon-check feature-check">
                <li>API development (REST and SOAP, OAuth Standard)</li>
                <li>API integration (REST and SOAP)</li>
                <li>API Proof of Concept integrations</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 service-container">
            <img
              src={MachineLearning}
              className="service-icon"
              alt="machine learning"
            />
            <h5 className="font-weight-normal margin-top-20">
              Machine Learning
            </h5>
            <div className="left-align">
              <p>
                Take advantage of the latest developments in AI and machine
                learning to help push your business forward and ahead of your
                competitors.
              </p>
              <ul className="list-icon list-icon-check feature-check">
                <li>Computer Vision - Analysis of Images</li>
                <li>Data trends and recommendations</li>
                <li>Natural Language Processing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
