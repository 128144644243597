import { useState, useEffect } from "react";
import Logo from "../images/logo.png";

export function Header() {
  const [state, setState] = useState({
    stickyClass: "relative",
    mobileMenuOpen: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  });

  function setStickyClass(stickyClass: string) {
    setState({ ...state, stickyClass });
  }

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      windowHeight > 1 ? setStickyClass("sticky") : setStickyClass("");
    }
  };

  function toggleMobileMenu() {
    setState({ ...state, mobileMenuOpen: !state.mobileMenuOpen });
  }

  function menuLinkClicked() {
    setState({ ...state, mobileMenuOpen: false });
  }

  return (
    <header className={"header " + state.stickyClass} id="home">
      <div className="container">
        <a href="/">
          <img className="header-logo" src={Logo} alt="TidyCode" />
        </a>
        <div className={"header-menu " + (state.mobileMenuOpen ? "show" : "")}>
          <ul className="nav">
            <li className="nav-item">
              <a
                className="nav-link"
                href="#services"
                onClick={menuLinkClicked}
              >
                Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#technology"
                onClick={menuLinkClicked}
              >
                Technology
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about" onClick={menuLinkClicked}>
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact" onClick={menuLinkClicked}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <button
          className={
            "header-toggle " + (state.mobileMenuOpen ? "toggle-close" : "")
          }
          onClick={toggleMobileMenu}
        >
          <span></span>
        </button>
      </div>
    </header>
  );
}
