import LanguagesImage from "../images/services/languages.png";
import DatabaseImage from "../images/services/db.png";
import FrontEndImage from "../images/services/front-end.png";
import MobileImage from "../images/services/mobile.png";
import CloudImage from "../images/services/cloud.png";
import EcommerceImage from "../images/services/ecommerce.png";

export function Technology() {
  return (
    <div id="technology" className="section alternate-section">
      <div className="container text-center">
        <div className="margin-bottom-70">
          <div className="row text-center">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="font-weight-normal">Technology</h2>
            </div>
          </div>
        </div>
        <div className="row icon-5xl">
          <div className="col-12 col-md-4 technology-container">
            <h5 className="font-weight-normal margin-top-20">
              Programming Languages
            </h5>
            <img src={LanguagesImage} alt="Microsoft .NET, Java, NodesJS" />
            <div className="separator"></div>
          </div>
          <div className="col-12 col-md-4 technology-container">
            <h5 className="font-weight-normal margin-top-20">
              Database Technologies
            </h5>
            <img
              src={DatabaseImage}
              alt="Microsoft SQL Server, MySQL, MongoDB"
            />
            <div className="separator"></div>
          </div>
          <div className="col-12 col-md-4 technology-container">
            <h5 className="font-weight-normal margin-top-20">
              Front-end Technologies
            </h5>
            <img src={FrontEndImage} alt="React, Angular, HTML5, CSS3" />
            <div className="separator"></div>
          </div>
          <div className="col-12 col-md-4 technology-container">
            <h5 className="font-weight-normal margin-top-20">
              Mobile Platforms
            </h5>
            <img src={MobileImage} alt="Android, iOS" />
            <div className="separator"></div>
          </div>
          <div className="col-12 col-md-4 technology-container">
            <h5 className="font-weight-normal margin-top-20">
              Cloud Platforms
            </h5>
            <img src={CloudImage} alt="Amazon Web Services, Microsoft Azure" />
            <div className="separator"></div>
          </div>
          <div className="col-12 col-md-4 technology-container">
            <h5 className="font-weight-normal margin-top-20">
              eCommerce Platforms
            </h5>
            <img src={EcommerceImage} alt="Shopify BigCommerce" />
            <div className="separator"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
