import AboutUsImage from "../images/about-us.jpg";

export function About() {
  return (
    <div id="about" className="section">
      <div className="container">
        <div className="margin-bottom-70 text-center">
          <h2 className="font-weight-normal">About TidyCode</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <img src={AboutUsImage} alt="team" />
          </div>
          <div className="col-12 col-md-6">
            <h5>
              We're a talented team of software developers that love helping
              local businesses
            </h5>
            <p>
              Our team of passionate and talented developers are here to help
              solve any technical business problem that is holding your business
              back. We love being part of the local community and helping local
              businesses thrive. The team at TidyCode can help with all your
              development needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
