export function Footer() {
  return (
    <footer>
      <div className="footer-section-sm footer-dark">
        <div className="container">
          <div className="row col-spacing-10">
            <div className="col-12 col-md-6 text-md-left">
              <p>© {new Date().getFullYear()} TidyCode, All Rights Reserved.</p>
              <p className="grecaptcha--tac">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </p>
            </div>
            <div className="col-12 col-md-6 text-md-right">
              <a
                className="social-link"
                href="https://twitter.com/TidyCodeDev"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="social-link"
                href="https://www.linkedin.com/company/tidycode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
