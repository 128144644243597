import { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./components/Headter";
import { Services } from "./components/Services";
import { Technology } from "./components/Technology";
import { About } from "./components/About";
import { Footer } from "./components/Footer";
import { Contact } from "./components/Contact";
import { ContactHubspot } from "./components/ContactHubspot";

function App() {
  const [state, setState] = useState({
    scrollToTopClass: "",
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollToTop);

    return () => {
      window.removeEventListener("scroll", scrollToTop);
    };
  });

  function setStickyClass(scrollToTopClass: string) {
    setState({ ...state, scrollToTopClass });
  }

  const scrollToTop = () => {
    if (window !== undefined) {
      const scrollPosition = window.scrollY;
      scrollPosition > window.innerHeight - 5
        ? setStickyClass("scrolltotop-show")
        : setStickyClass("");
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="header-section">
          <div className="header-overlay">
            <div className="container text-center">
              <div className="position-middle">
                <h1>Digital Solutions That Build Businesses.</h1>
                <h4 className="font-weight-normal">
                  Australian based team of developers and designers here to help
                  your business succeed
                </h4>
              </div>

              <div className="position-bottom">
                <a
                  className="button-circle button-circle-lg button-circle-outline-white icon-lg"
                  href="#services"
                >
                  <i className="fa-solid fa-arrow-down arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Services />
        <Technology />
        <About />
        <ContactHubspot />
      </main>
      <Footer />
      <div className={"scrolltotop " + state.scrollToTopClass}>
        <a
          className="button-circle button-circle-sm button-circle-dark"
          href="#"
          onClick={() => window.scrollTo(0, 0)}
        >
          <i className="fa-solid fa-arrow-up arrow-up" aria-hidden="true"></i>
        </a>
      </div>
    </>
  );
}

export default App;
